import React from 'react';
import '../App.css';
import privacyPolicy from '../assets/privacypolicy.pdf';


const PrivacyPolicyPage = () => {
    return (
        <div className="App d-flex flex-column" style={{ height: '85vh' }}>
            <iframe
                src={privacyPolicy}
                title='Privacy Policy'
                width="100%"
                height="100%"
            />
        </div>
    );
};

export default PrivacyPolicyPage;