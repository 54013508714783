import React from 'react';
import '../App.css';
import termsAndConditions from '../assets/termsandconditions.pdf';


const TermsAndConditionsPage = () => {
    return (
        <div className="App d-flex flex-column" style={{ height: '85vh' }}>
            <iframe
                src={termsAndConditions}
                title='Terms and Conditions'
                width="100%"
                height="100%"
            />
        </div>
    );
};

export default TermsAndConditionsPage;