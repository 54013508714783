import React from 'react';
import '../App.css';
import appStoreBadge from '../assets/app-store-badge.png';
import playStoreBadge from '../assets/play-store-badge.png';
import appOnPhone from '../assets/app-on-phone.png';

const HomePage = () => {
    return (
        <div className="App">
            <body className="text-white d-flex flex-column">
                <section className="bg-gradient flex-1 d-flex align-items-stretch">
                    <div className="container d-flex align-items-center">
                        <div className="d-none col-md-5 d-md-block col-lg-4">
                            <img src={appOnPhone} alt="Preview Image" className="img-fluid" />
                        </div>

                        <div className="py-5 col-md-7 col-lg-8">
                            <h1 className="text-center mt-0 mb-2 text-md-left">RoomTree</h1>

                            <h3 className="text-center my-0 text-md-left mb-md-5">
                                Redefining shared living arrangements
                            </h3>

                            <div className="text-center d-md-none">
                                <img
                                    src={appOnPhone}
                                    alt="Preview Image"
                                    className="preview-image-small"
                                />
                            </div>

                            <h5>
                                RoomTree is the revolutionary platform designed to simplify your search for compatible roommates and room/basement sublets. We're on a mission to redefine the way people find shared living spaces.
                            </h5>

                            <ul className="list-unstyled mb-4 py-1 mb-md-5">
                                <li className="d-flex py-2">
                                    <i className="fa fa-check-circle pr-4" aria-hidden="true"></i>
                                    Real-Time Messaging: Seamlessly communicate with potential roommates
                                </li>
                                <li className="d-flex py-2">
                                    <i className="fa fa-check-circle pr-4" aria-hidden="true"></i>
                                    Listings Made Easy: Create detailed room and roommate listings effortlessly
                                </li>
                                <li className="d-flex py-2">
                                    <i className="fa fa-check-circle pr-4" aria-hidden="true"></i>
                                    Filter and Map Views: Easily find listings based on your preferences and view them on a map
                                </li>
                                <li className="d-flex py-2">
                                    <i className="fa fa-check-circle pr-4" aria-hidden="true"></i>
                                    Social Sign In: Join using your preferred social media accounts
                                </li>
                                <li className="d-flex py-2">
                                    <i className="fa fa-check-circle pr-4" aria-hidden="true"></i>
                                    User Safety Controls: Flag objectionable content and block abusive users for a safer community experience
                                </li>
                            </ul>

                            <div className="text-center text-md-left info-text">
                                <p>
                                    Stay tuned for upcoming features! We're continuously working to enhance your RoomTree experience
                                </p>
                            </div>

                            <div className="text-center text-md-left">
                                <a
                                    href="https://apps.apple.com/ca/app/roomtree/id6473100495" // replace with your App Store URL
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img src={appStoreBadge} alt="App Store Link" className="app-badge" />
                                </a>

                                <a
                                    //href="https://linktr.ee/roomtree" replace with your Play Store URL
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img src={playStoreBadge} alt="Play Store Link" className="app-badge" />
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
            </body>
        </div>
    );
};

export default HomePage;