import React from 'react';
import '../App.css';
import { Link } from 'react-router-dom';
import instagramLogo from '../assets/instagram_logo.png';
import linkedinLogo from '../assets/linkedIn_logo.png';
import facebookLogo from '../assets/facebook_logo.png';

function Footer() {
    return (
        <footer className="footer-black text-white">
            <div className="container py-5">
                <div className="d-flex flex-column flex-md-row justify-content-between align-items-center">
                    <div className="d-flex flex-wrap justify-content-center align-items-center mb-2 mb-md-0 w-100">
                        <div className="mx-2 mb-2">
                            <a href="https://www.instagram.com/roomtreeapp/" target="_blank" rel="noopener noreferrer">
                                <img src={instagramLogo} alt="Instagram Logo" className="app-logo" />
                            </a>
                        </div>
                        <div className="mx-2 mb-2">
                            <a href="https://www.linkedin.com/company/roomtree/" target="_blank" rel="noopener noreferrer">
                                <img src={linkedinLogo} alt="LinkedIn Logo" className="app-logo" />
                            </a>
                        </div>
                        <div className="mx-2 mb-2">
                            <a href="https://www.facebook.com/profile.php?id=100089619496433" target="_blank" rel="noopener noreferrer">
                                <img src={facebookLogo} alt="Facebook Logo" className="app-logo" />
                            </a>
                        </div>
                        <div className="mx-2 mb-2">
                            <Link className="text-white" to="/">Home</Link>
                        </div>
                        <div className="mx-2 mb-2">
                            <Link className="text-white" to="/privacy-policy">Privacy Policy</Link>
                        </div>
                        <div className="mx-2 mb-2">
                            <Link className="text-white" to="/terms-and-conditions">Terms &amp; Conditions</Link>
                        </div>
                    </div>
                    <div className="text-center text-md-right w-100 w-md-auto mt-2 mt-md-0">
                        <p className="mb-0">Copyright &copy; RoomTree. All rights reserved.</p>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
